import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import ContactUs from "./Pages/ContactUs";
import Vision from "./Pages/Vision";
import PageNotFound from "./Pages/PageNotFound";
import IndProj from "./Pages/IndProj";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
// import Careers from "./Pages/Careers";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index Component={Home} />
        <Route exact path="/" Component={Home} />
        {/* <Route exact path="/projects/id/:id" Component={Projects} />
         */}
        <Route exact path="/projects" Component={Projects} />
        <Route exact path="/contact" Component={ContactUs} />
        <Route exact path="/vision" Component={Vision} />
        <Route exact path="/projects/id/:id" Component={IndProj} />

        <Route path="*" element={<PageNotFound />} />
        {/* <Route exact path="contact#careers" Component={ContactUs} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
