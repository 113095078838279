import React, { useEffect, useState } from "react";
import Navigationbar from "../Components/Navbar/Navbar";
import Spacer from "../Components/Spacer/Spacer";
import Footer from "../Components/Footer/Footer";
import { useParams } from "react-router-dom";
import { projects } from "../Utils/projects";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import "./IndProj.css";
// import next from "../Assets/next.svg";

function IndProj() {
  const { id } = useParams();
  let selectedProj = projects.filter((proj) => id == proj.id);
  let imgArray = selectedProj[0].images;

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    imgArray.forEach((pic) => {
      const img = new Image();
      img.src = pic;
    });
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const desktopSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 4000,
    pauseOnHover: false,
  };
  const mobileSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 4000,
    pauseOnHover: false,
  };
  return (
    <>
      <Navigationbar />
      <Spacer />

      {selectedProj.length === 1 && (
        <>
          <Container className="projOuter">
            <h1>{selectedProj[0].caption}</h1>
            {width > 1023 ? (
              <Slider {...desktopSettings} className="projSlider">
                {selectedProj[0].images.map((slide, index) => (
                  <div key={index}>
                    <img
                      src={slide}
                      width={"100%"}
                      alt={selectedProj.caption}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider {...mobileSettings} className="projSlider">
                {selectedProj[0].images.map((slide, index) => (
                  <div key={index}>
                    <img
                      src={slide}
                      width={"100%"}
                      alt={selectedProj.caption}
                    />
                  </div>
                ))}
              </Slider>
            )}

            {selectedProj[0].description && (
              <div className="desc">{selectedProj[0].description}</div>
            )}
          </Container>
        </>
      )}
      <Footer />
    </>
  );
}

export default IndProj;
