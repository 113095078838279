import { motion } from "framer-motion";
import logo from "../../Assets/logo.webp";
import "./AnimatedLogo.css";

const AnimatedLogo = () => {
  return (
    <div className="brandLogo">
      <div className="imgcontainer">
        <img src={logo} height={84} width={84} className="logo" alt="logo"/>
        <div className="stripe"/>
      </div>
    </div>
  );
};
export default AnimatedLogo;
