//---------------make array in the format: [[{},{},{}],[{},{},{}]]
//--------------2D array with 3 elements in the child

import mysore1_1 from "../Assets/Projects/Binoy/1.webp";
import mysore1_1DI from "../Assets/Projects/Binoy/1DisplayImage.webp";
import mysore1_2 from "../Assets/Projects/Binoy/2.webp";
import mysore1_3 from "../Assets/Projects/Binoy/3.webp";
import mysore1_4 from "../Assets/Projects/Binoy/4.webp";
import mysore1_5 from "../Assets/Projects/Binoy/5.webp";
import mysore1_6 from "../Assets/Projects/Binoy/6.webp";
import mysore1_7 from "../Assets/Projects/Binoy/7.webp";
import mysore1_8 from "../Assets/Projects/Binoy/8.webp";
import mysore1_9 from "../Assets/Projects/Binoy/9.webp";

import mys2_1DI from "../Assets/Projects/Raghavendra/mysore2_1 displayImage.webp";
import mys2_1 from "../Assets/Projects/Raghavendra/mysore2_1.webp";
import mys2_2 from "../Assets/Projects/Raghavendra/mysore2_2.webp";
import mys2_3 from "../Assets/Projects/Raghavendra/mysore2_3.webp";
import mys2_5 from "../Assets/Projects/Raghavendra/mysore2_5.webp";

import rav1 from "../Assets/Projects/Ravirao/ravirao1.webp";
import rav2 from "../Assets/Projects/Ravirao/ravirao6.webp";
import rav3 from "../Assets/Projects/Ravirao/ravirao7.webp";
import rav4 from "../Assets/Projects/Ravirao/ravirao8.webp";

import sn1 from "../Assets/Projects/srinivas/sn1.webp";
import sn2 from "../Assets/Projects/srinivas/sn2.webp";

import as1 from "../Assets/Projects/Ashok/ashok1.webp";

import mh1 from "../Assets/Projects/Manohar/manohar.webp";

import ps1 from "../Assets/Projects/Purshottam/purushottam.webp";

export const projects = [
  {
    images: [
      mysore1_1,
      mysore1_2,
      mysore1_3,
      mysore1_4,
      mysore1_5,
      mysore1_6,
      mysore1_7,
      mysore1_8,
      mysore1_9,
      // mysore1_10,
    ],
    displayImage: mysore1_1DI,
    caption: "Mr. Binoy's Residence",
    description: `A personalized commitment to shaping spaces that resonate with our client's values and lifestyle. By infusing their principles into the design process, this residence becomes a rhythmic and devotional experience, where every corner reflects its essence and enhances the quality of life`,
    id: 1,
    name: "Mysore1",
    property: "Residential",
    category: "Architecture",
  },
  {
    images: [mys2_1, mys2_2, mys2_3, mys2_5],
    displayImage: mys2_1DI,
    caption: "Mr. Raghavendra's Residence",
    description: `We create luxurious environments that inspire productivity and creativity. Thoughtful selection of materials and spatial arrangements ensures comfort while allowing for adaptability to various needs. This harmonious blend of aesthetics and functionality cultivates an inspiring and versatile living environment, enhancing the overall quality of life.`,
    id: 2,
    name: "Mysore2",
    property: "Residential",
    category: "Architecture",
  },
  {
    images: [rav1, rav2, rav3, rav4],
    displayImage: rav1,
    caption: "Mr. Ravi Rao's Residence",
    description: ``,
    id: 3,
    name: "RaviRao",
    property: "Residential",
    category: "Architecture",
  },
  {
    images: [as1],
    displayImage: as1,
    caption: "Mr. Ashok's Residence",
    description: ``,
    id: 5,
    name: "Ashok",
    property: "Residential",
    category: "Architecture",
  },
  {
    images: [sn1, sn2],
    displayImage: sn1,
    caption: "Srinivasa Nilaya",
    description: ``,
    id: 4,
    name: "Srinivasa Nilaya",
    property: "Residential",
    category: "Architecture",
  },

  {
    images: [ps1],
    displayImage: ps1,
    caption: "Mr. Purushottam's Residence",
    description: ``,
    id: 6,
    name: "Purushottam",
    property: "Residential",
    category: "Architecture",
  },
  {
    images: [mh1],
    displayImage: mh1,
    caption: "Mr. Manohar's Residence",
    description: ``,
    id: 7,
    name: "Manohar",
    property: "Residential",
    category: "Architecture",
  },
];

const sortProj = () => {
  let defaultSortedProjects = [[]];
  let dimensionCounter = 0;

  for (let i = 0; i < projects.length; i++) {
    if (i !== 0 && i % 3 === 0) {
      defaultSortedProjects.push([]);
      dimensionCounter += 1;
    }
    defaultSortedProjects[dimensionCounter].push(projects[i]);
  }
  return defaultSortedProjects;
};

export const sortedProj = sortProj();
