import React from "react";
import "./Footer.css";
import AnimatedLogo from "../AnimatedLogo/AnimatedLogo";
import email from "../../Assets/email-icon.svg";
import phone from "../../Assets/phone-icon.svg";
import loc from "../../Assets/locationIcon.svg";

import Container from "react-bootstrap/Container";

function Footer() {
  return (
    <Container>
      <div className="spacer1"></div>

      <div className="footerMobile">
        <div className="leftPart">
          <AnimatedLogo />
        </div>

        <div className="rightPart">
          <div className="rightTop">
            <div className="email">info@revamphivestudio.in</div>
            <div className="phones">
              <div className="phone1">+91 9481531196</div>
              <div className="phone2">+91 8073194553</div>
            </div>
          </div>

          <div className="rightBottom">
            <div className="address">
              <div className="makeBold">
                <strong>Mysore Office</strong>
              </div>
              <div>16/A Gokulam Main Road</div>
              <div>VV Mohalla</div>
              <div>Mysuru, Karnataka 570002</div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="leftcontent">
          <div className="email">
            <div className="emailid">info@revamphivestudio.in</div>
            <div className="emaillogo">
              <img src={email} alt="email" width={20} height={23} />
            </div>
          </div>
          <br className="hided" />
          <div className="phone">
            <div className="phones">
              <div className="phone1">+91 9481531196</div>
              <div className="phone2">+91 8073194553</div>
            </div>
            <div className="phoneicon">
              <img src={phone} alt="phone" width={20} height={23} />
            </div>
          </div>
        </div>
        <div className="centerlogo">
          <AnimatedLogo />
        </div>
        <div className="rightcontent">
          <div className="addressicon">
            <img src={loc} alt="location" width={20} height={23} />
          </div>
          <div className="address">
            <div className="makeBold">
              <strong>Mysore Office</strong>
            </div>
            <div>16/A Gokulam Main Road</div>
            <div>VV Mohalla</div>
            <div>Mysuru, Karnataka 570002</div>
          </div>
        </div>
      </div>
      <div className="credits">
        <div>&#169; 2022 REVAMP HIVE STUDIO</div>
        <div>
          All rights reserved | Created by{" "}
          <a href="https://sumanth-hegde-profile.vercel.app/" target="blank">
            Sumanth Hegde
          </a>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
