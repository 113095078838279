import React from "react";
import "./NavbarButton.css";
const NavbarButton = ({ props }) => {
  return (
    <>
      <div className="toplink">
        <span className="btntext">
          <span className="btntext-inner">{props}</span>
          <svg
            className="line"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <g>
              <path d="M 13.8,24.196c 0.39,0.39, 1.024,0.39, 1.414,0l 6.486-6.486c 0.196-0.196, 0.294-0.454, 0.292-0.71 c0-0.258-0.096-0.514-0.292-0.71L 15.214,9.804c-0.39-0.39-1.024-0.39-1.414,0c-0.39,0.39-0.39,1.024,0,1.414L 19.582,17 L 13.8,22.782C 13.41,23.172, 13.41,23.806, 13.8,24.196z"></path>
            </g>
          </svg>
        </span>
      </div>
    </>
  );
};

export default NavbarButton;
