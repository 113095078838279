import React from "react";
import "./Parallax.css";
import logo from "../../Assets/nobglogo.webp";

function ParallaxBanner() {
  return (
    <div className="bgmimg" rel="preload">
      <div className="desktopTitle">PROJECTS</div>
    </div>
  );
}

export default ParallaxBanner;
