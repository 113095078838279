import React from "react";
import Navigationbar from "../Components/Navbar/Navbar";
import Spacer from "../Components/Spacer/Spacer";
import ContactCard from "../Components/ContactCard/ContactCard";
import Map from "../Components/Map/Map";
import { Container } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
function ContactUs() {
  return (
    <>
      <Navigationbar />
      <Spacer />
      <Container>
        <ContactCard />
        <Map />
      </Container>
      <Spacer />
      <Footer/>
    </>
  );
}

export default ContactUs;
