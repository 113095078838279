import Carousel from "../Components/Carousel/Carousel";
import Footer from "../Components/Footer/Footer";
import IntroComponent from "../Components/IntroComponent/IntroComponent";
import Navigationbar from "../Components/Navbar/Navbar";
import Reviews from "../Components/Review/Reviews";

function Home() {
  return (
    <>
      <Navigationbar />
      <Carousel />
      <IntroComponent />
      <Reviews />
      <Footer />
    </>
  );
}

export default Home;
