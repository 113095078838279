import React from "react";
import "./Reviews.css";
import Slider from "react-slick";
const desktopSliderSettings = {
  //   dots: true,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed: 600,
  autoplaySpeed: 5000,
  pauseOnHover: false,
};

const mobileSliderSettings = {
  //   dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 600,
  autoplaySpeed: 5000,
  pauseOnHover: false,
};
const reviewList = [
  {
    title: "Lakshman Raju",
    desc: "It was nice working with the team on our dream home plan, they are very creative and very easy to work with. The enthusiasm and passion they bring to every project and the way the team shows that personal interest in the project goes a long way in getting closer to the dream home. Thanks Revamp hive studio for helping us on our house plan.",
  },
  {
    title: "Nithin Kantharaj",
    desc: "One of the best and knowledgeable person to carter all construction needs.",
  },
  {
    title: "Sameeksha Holalu",
    desc: "Walked in for a free consultation and had a good experience.",
  },
  {
    title: "Deepa Raj",
    desc: "Had a great experience with Revamp in renovation.",
  },
  {
    title: "Anudeep T Channaiah",
    desc: "Creative and trustworthy architects.",
  },
  {
    title: "Ishwar Naganur",
    desc: "I was impressed by the professionalism, attention to detail, and promptness of this firm. Communication is important when dealing with changes, as they take place on every project, and this firm was on point every step of the way. I have been working with them from  some years now and fantastic all the way through, getting drawings done and making a plan for an application for a retrospective planning application.",
  },
  {
    title: "Kamal (That Music Company)",
    desc: "Revamp Hive Studio is truly the pinnacle of architecture and design excellence. Their innovative approach, attention to detail, and commitment to creating spaces that inspire and captivate are unparalleled. If you're looking for the best in architecture and design, look no further than Revamp Hive Studio.Mr. Rakshith & team top notch.",
  },
  {
    title: "Devaraj Singonahalli",
    desc: "I am a Civil Engineer by profession and having a good 25 years of managing projects.Having met Rakshith and his team at Revamp Hive Studio was a pleasant surprise.They are team of young professionals designing Residences, Commercial spaces and Interiors.I found them highly motivated,Creative and full of enthusiasm.I wish them the very best in all their future endeavours.",
  },
];
function Reviews() {
  return (
    <div className="reviewContainer container">
      <div className="containerTitle">Our Clients feedback</div>
      <Slider {...desktopSliderSettings} className="desktopSlider">
        {reviewList.map((review, index) => (
          <ReviewCard props={review} key={index} />
        ))}
      </Slider>

      <Slider {...mobileSliderSettings} className="mobileSlider">
        {reviewList.map((review, index) => (
          <ReviewCard props={review} key={index} />
        ))}
      </Slider>
    </div>
  );
}

const ReviewCard = ({ props }) => {
  return (
    <div className="reviewCard">
      <div className="stylediv"></div>
      <div className="reviewcard-title">{props.title}</div>
      <div className="reviewcard-desc">{props.desc}</div>
    </div>
  );
};

export default Reviews;
