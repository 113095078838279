import React from "react";
import "./Objectives.css";
import { Container } from "react-bootstrap";
function Objectives() {
  return (
    <Container>
      <div className="objectives">
        <div className="title">
          <h1>OBJECTIVES</h1>
        </div>
        <div className="body">
          <div className="vline"></div>
          <div className="section">
            <div className="centerDiv"></div>
            <div className="hider"></div>
            <div className="heading">Design Philosophy</div>
            <div className="sectionBody">
              We believe that exceptional architecture emerges from the seamless
              integration of purpose, aesthetics, and user experience. Our
              designs prioritize functionality, celebrate details, and resonate
              with the essence of each space. Welcome to a world where form and
              function dance in harmony.
            </div>
          </div>

          <div className="section">
            <div className="centerDiv"></div>
            <div className="hider"></div>
            <div className="heading">Specifics</div>
            <div className="sectionBody">
              We provide complete architecture solutions including Residential
              architecture, Commercial architecture and even Interior Design. We
              focus on transforming any space into a harmonious and visually
              appealing environment.
            </div>
          </div>

          <div className="section">
            <div className="centerDiv"></div>
            <div className="hider"></div>
            <div className="heading">Detailing</div>
            <div className="sectionBody">
              We believe that every detail matters in creating exceptional
              spaces. Our meticulous attention to detail ensures that the final
              design exceeds your expectations.
            </div>
          </div>

          <div className="section">
            <div className="centerDiv"></div>
            <div className="hider"></div>
            <div className="heading">Creativity</div>
            <div className="sectionBody">
              Our architectural expertise blends innovation, functionality, and
              aesthetics. From visionary blueprints to awe-inspiring structures,
              we transform spaces with passion and precision. We focus on
              building a world where imagination meets architectural finesse.
            </div>
          </div>

          <div className="section">
            <div className="centerDiv"></div>
            <div className="hider"></div>
            <div className="heading">Client Satisfaction</div>
            <div className="sectionBody">
              At Revamp, your satisfaction is our top priority. We go above and
              beyond to ensure that our clients are delighted with the final
              result.
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Objectives;
