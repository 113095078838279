import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ProjectContainer.css";
import { projects } from "../../Utils/projects";
import { Link } from "react-router-dom";

const ProjectContainer = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Container className="projectContainer">
      <h1 className="projectContainerTitle">
        Check out all our projects here.
      </h1>
      <div className="desktopViewer">
        <Row>
          {projects &&
            projects.map((project, index) => (
              <Col lg={6} key={index} className="coll">
                <Link
                  className="leftBigImage"
                  to={`/projects/id/${project.id}`}
                >
                  {width > 1023 && width < 1201 ? (
                    <img
                      className="desktopImage"
                      src={project.displayImage}
                      width={420}
                      height={294}
                      alt={project.caption}
                    />
                  ) : width > 1023 ? (
                    <img
                      className="desktopImage"
                      src={project.displayImage}
                      width={490}
                      height={343}
                      alt={project.caption}
                    />
                  ) : width > 767 && width < 1024 ? (
                    <img
                      className="tabImage"
                      src={project.displayImage}
                      width={380}
                      height={265}
                      alt={project.caption}
                    />
                  ) : (
                    <img
                      className="mobileImage"
                      src={project.displayImage}
                      width={290}
                      height={202}
                      alt={project.caption}
                    />
                  )}
                  <div className="caption">{project.caption}</div>
                </Link>
              </Col>
            ))}
        </Row>
      </div>
    </Container>
  );
};

export default ProjectContainer;
