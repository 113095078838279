import React from "react";

function Map() {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1948.8991759415148!2d76.6279563379071!3d12.329355223425164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf7bd349f65a65%3A0x7977bb59b5a4886b!2sRevamp%20Hive%20Studio!5e0!3m2!1sen!2sin!4v1711525260297!5m2!1sen!2sin"
        width="100%"
        height="600"
        style={{ border: "1px solid grey", borderRadius: "20px" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
