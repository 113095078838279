import React from "react";
import Navigationbar from "../Components/Navbar/Navbar";
import Objectives from "../Components/Objectives/Objectives";
import Footer from "../Components/Footer/Footer";
import Hives from "../Components/Hives/Hives";

function Vision() {
  return (
    <>
      <Navigationbar />
      <Objectives />
      <Hives />
      <Footer />
    </>
  );
}

export default Vision;
