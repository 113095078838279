import React, { useEffect } from "react";
import Slider from "react-slick";

import c1 from "../../Assets/imagesv2/desktop/carousel1.webp";
import c2 from "../../Assets/imagesv2/desktop/carousel2.webp";
import c3 from "../../Assets/imagesv2/desktop/carousel3.webp";
// import c4 from "../../Assets/imagesv2/desktop/carousel4.webp";
import c5 from "../../Assets/imagesv2/desktop/carousel5.webp";
import c6 from "../../Assets/imagesv2/desktop/carousel6.webp";
import c7 from "../../Assets/imagesv2/desktop/carousel7.webp";
import c8 from "../../Assets/imagesv2/desktop/carousel8.webp";
import c9 from "../../Assets/imagesv2/desktop/carousel9.webp";

import m1 from "../../Assets/imagesv2/Mobile/carousel1.webp";
import m2 from "../../Assets/imagesv2/Mobile/carousel2.webp";
import m3 from "../../Assets/imagesv2/Mobile/carousel3.webp";
import m4 from "../../Assets/imagesv2/Mobile/carousel4.webp";
import m5 from "../../Assets/imagesv2/Mobile/carousel5.webp";
import m6 from "../../Assets/imagesv2/Mobile/carousel6.webp";
import m7 from "../../Assets/imagesv2/Mobile/carousel7.webp";
import m8 from "../../Assets/imagesv2/Mobile/carousel8.webp";
import m9 from "../../Assets/imagesv2/Mobile/carousel9.webp";

import "./Carousel.css";
import { Container } from "react-bootstrap";

function Carousel() {
  let imgArray = [
    c1,
    c2,
    c3,

    c5,
    c6,
    c7,
    c8,
    c9,
    m1,
    m2,
    m3,
    m4,
    m5,
    m6,
    m7,
    m8,
    m9,
  ];
  useEffect(() => {
    //prefetching
    imgArray.forEach((pic) => {
      const img = new Image();
      img.src = pic;
    });
  }, []);

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    infinite: true,
  };

  const desktopArray = [c1, c2, c3, c5, c6, c7, c8, c9];
  const mobileArray = [m1, m2, m3, m4, m5, m6, m7, m8, m9];
  return (
    <Container>
      <div className="carousel-desktop">
        <Slider {...settings}>
          {desktopArray.map((pic, index) => (
            <div key={`carousel${index}`}>
              <img src={pic} width={"100%"} alt={`carousel${index}`}></img>
            </div>
          ))}
        </Slider>
      </div>

      <div className="carousel-mobile">
        <Slider {...settings}>
          {mobileArray.map((pic, index) => (
            <div key={`carousel${index}`}>
              <img src={pic} width={"100%"} alt={`carousel${index}`}></img>
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
}

export default Carousel;
