import React, { useEffect } from "react";
import "./Hives.css";
import rakshith from "../../Assets/hive/rak.webp";
import meghana from "../../Assets/hive/meghana.webp";
import swati from "../../Assets/hive/swat.webp";
import parinitha from "../../Assets/hive/pranitha.webp";
import bhavani from "../../Assets/hive/bhavani.webp";
import gear from "../../Assets/gear.svg";
import compass from "../../Assets/compass.svg";
import { Col, Container, Row } from "react-bootstrap";
function Hives() {
  useEffect(() => {
    let target = window.location.hash;
    target && document.querySelector(target).scrollIntoView();
  }, []);

  return (
    <div className="hives">
      <div id="hives" className="title">
        OUR HIVE
      </div>
      <Container className="rowContainer">
        <Row className="note">
          <Col lg={4} className="colm">
            <div className="imgContainer">
              <img src={rakshith} width={290} height={290} alt="Rakshith" />
              <div className="name">Ar. Rakshith R</div>
              <div className="caps">Founder and Principal Architect</div>
              <div className="degree">
                B.Arch, School of Planning and Architecture
              </div>
              <div className="degree">University Of Mysore</div>
            </div>
          </Col>
          <div className="vectors">
            <div className="gear">
              <img
                src={gear}
                width={300}
                height={300}
                alt="gear"
                className="gear1"
              ></img>
              <img
                src={gear}
                width={300}
                height={300}
                alt="gear"
                className="gear2"
              ></img>
            </div>
          </div>
          <Col lg={8} className="noteContainer">
            <div className="noteTitle">Note from the Founder</div>
            <div className="content">
              In our architecture studio, we believe in keeping things simple.
              We think simplicity is important because it helps us focus on what
              really matters in our designs. It's not about making things boring
              or plain, but about finding a balance that makes our buildings
              functional, beautiful, and easy to understand. We pay attention to
              how spaces flow, use materials wisely, and care about the
              environment. Our goal is to create buildings that are easy to use,
              look good, and stand strong for a long time. Simplifying our
              approach helps us connect with our clients' needs and make a
              positive impact on the places where people live and work.
            </div>
          </Col>
        </Row>

        <Row>
          {/* <Col lg={4} className="colm">
            <div className="imgContainer">
              <img src={somanna} width={235} height={235} alt="Somanna" />
              <div className="name">Ar. M K Somanna</div>
              <div className="caps">Associate Architect</div>
              <div className="degree">
                B.Arch, School of Planning and Architecture
              </div>
              <div className="degree">University Of Mysore</div>
            </div>
          </Col> */}
          <Col lg={4} className="colm">
            <div className="imgContainer">
              <img src={swati} width={235} height={235} alt="Swati" />
              <div className="name">Ar. Swati Pethkar</div>
              <div className="caps">Junior Architect</div>
              <div className="degree">
                B.Arch, School of Planning and Architecture
              </div>
              <div className="degree">University Of Mysore</div>
            </div>
          </Col>
          <Col lg={4} className="colm">
            <div className="imgContainer">
              <img src={parinitha} width={235} height={235} alt="Parinitha" />
              <div className="name">Parinita N B</div>
              <div className="caps">Interior Designer</div>
              <div className="degree">Mysore</div>
            </div>
          </Col>
          <Col lg={4} className="colm">
            <div className="imgContainer">
              <img src={bhavani} width={235} height={235} alt="Bhavani" />
              <div className="name">Bhavani Shankar</div>
              <div className="caps">Civil Engineer</div>
              <div className="degree">B.E, SJCE</div>
              <div className="degree">Mysore</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="colm">
            <div className="imgContainer">
              <img src={meghana} width={235} height={235} alt="Meghana" />
              <div className="name">Meghana Ramesh</div>
              <div className="caps">Junior Architect</div>
              <div className="degree">Mysore</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Hives;
