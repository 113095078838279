import React from "react";
import "./ErrorComp.css";
import { Link } from "react-router-dom";

import ErrorImg from "../../Assets/Error/error1.webp";
function ErrorComp() {
  return (
    <div className="errorComp">
      <div className="title">This page does not exist!</div>
      <div className="title2">
        Sorry the page you were looking for cannot be found.
      </div>
      <div className="imgContainer">
        <img src={ErrorImg} width={600} alt="404" height={343} />
      </div>
      <div className="footertext">
        You can return to <Link to="/">Home</Link> page.
      </div>
    </div>
  );
}

export default ErrorComp;
