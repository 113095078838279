import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import AnimatedLogo from "../AnimatedLogo/AnimatedLogo";
import NavbarButton from "../NavbarButton/NavbarButton";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navigationbar = () => {
  return (
    <Navbar expand="lg" className="fixed-top white-back">
      <Navbar.Brand href="/">
        <AnimatedLogo />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="" />
      <Navbar.Collapse id="" className="nvbar">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/">
            <NavbarButton props={"Home"} />
          </Nav.Link>

          <Nav.Link as={Link} to="/vision" className="aboutus">
            <NavbarButton props={"About us"} />
            <div className="bigwrap">
              <div className="abtuscontainer">
                <Link to="/vision">
                  <div>Our Vision</div>
                </Link>
                <Link to="/vision#hives">
                  <div> Our Hive</div>
                </Link>
              </div>
            </div>
          </Nav.Link>

          <Nav.Link as={Link} to="/projects">
            <NavbarButton props={"Projects"} />
          </Nav.Link>

          {/* <Nav.Link as={Link} to="/blogs">
            <NavbarButton props={"Blogs"} />
          </Nav.Link> */}

          <Nav.Link as={Link} to="/contact">
            <NavbarButton props={"Contact Us"} />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default Navigationbar;
