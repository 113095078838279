import React from "react";
import "./ContactCard.css";
import logo from "../../Assets/logo.webp";
import ImageComponent from "../ImageComponent/ImageComponent";
import gmailIcon from "../../Assets/gmail-icon.svg";
import LinkedinIcon from "../../Assets/linkedin.svg";
import InstagramIcon from "../../Assets/instagram.svg";

function ContactCard() {
  let imgProps = {
    src: logo,
    width: "auto",
    height: "200px",
    alt: "logo",
  };
  return (
    <div className="contactCardContainer container">
      <div className="leftContainer">
        <ImageComponent props={imgProps} />
      </div>
      <div className="rightContainer">
        <div className="title">
          <strong>Revamp Hive Studio</strong>
        </div>

        <div className="address">
          <div className="makeBold">
            <strong>Mysore Office</strong>
          </div>
          <div>16/A Gokulam Main Road</div>
          <div>VV Mohalla</div>
          <div>Mysuru, Karnataka 570002</div>
        </div>

        <div className="phones">
          <div className="phone1">
            <a href="tel:+919481531196">+91 9481531196</a>
          </div>
          <div className="phone2">
            <a href="tel:+918073194553">+91 8073194553</a>
          </div>
        </div>

        <div className="email">
          <div className="emailid">
            <a href="mailto:info@revamphivestudio.in">
              <img
                src={gmailIcon}
                width={20}
                height={20}
                alt="gmail icon"
                className="gmailIcon"
              />
              info@revamphivestudio.in
            </a>
          </div>
        </div>

        <div className="socialLinks">
          <div className="linkedin">
            <a
              href="https://www.linkedin.com/company/revamp-hive-studio/about/"
              target="blank"
            >
              <img
                src={LinkedinIcon}
                width={20}
                height={20}
                alt="linkedin icon"
                className="linkedinIcon"
              />
            </a>
          </div>

          <div className="instagram">
            <a
              href="https://www.instagram.com/revamp_hive_studio?igshid=MzMyNGUyNmU2YQ%3D%3D"
              target="blank"
            >
              <img
                src={InstagramIcon}
                width={20}
                height={20}
                alt="instagram icon"
                className="instagramIcon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactCard;
