import React from "react";
import ErrorComp from "../Components/ErrorComponent/ErrorComp";
import Navigationbar from "../Components/Navbar/Navbar";

function PageNotFound() {
  return (
    <>
      <Navigationbar />
      <ErrorComp />;
    </>
  );
}

export default PageNotFound;
