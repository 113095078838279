import React from "react";

const ImageComponent = ({ props }) => {
  return (
    <img
      src={props.src}
      width={props.width}
      height={props.height}
      alt={props.alt}
    />
  );
};

export default ImageComponent;
