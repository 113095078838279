import Footer from "../Components/Footer/Footer";
import Navigationbar from "../Components/Navbar/Navbar";
import ProjectContainer from "../Components/ProjectContainer/ProjectContainer";
import Spacer from "../Components/Spacer/Spacer";
import ParallaxBanner from "../Components/parallaxBanner/ParallaxBanner";

function Projects() {
  return (
    <>
      <Navigationbar />
      <Spacer />
      <ParallaxBanner />
      <ProjectContainer />
      <Footer />
    </>
  );
}

export default Projects;
