import React from "react";
import "./IntroComponent.css";

function IntroComponent() {
  return (
    <div className="introContainer">
      <div className="centerintro">
        <div className="leftintro">
          <strong>What we do</strong>
        </div>
        <div className="rightintro">
          Revamp Hive Studio specializes in Innovative Architectural Design,
          creating spaces that seamlessly blend form and function. <br /> <br />
          Full-fledged architecture consultancy services are offered by Revamp
          Hive Studio, providing comprehensive design and project management
          expertise for a wide range of architectural projects.
        </div>
      </div>
    </div>
  );
}

export default IntroComponent;
